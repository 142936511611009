import * as React from "react";
import MonthlyHeader from "./MonthlyHeader";
import MonthlyDaysTableHeader from "./MonthlyDaysTableHeader";
import TaskList from "./TaskList";
import { AddTaskButton } from "./AddTaskButton";
import { AuthContext } from "../AuthContext";
import { ApiClientContext } from "../ApiClientContext";

export type TaskType = "boolean" | "numerical" | "emoji" | "photo";
export interface TaskCadence {
	value: number;
	period: "day" | "week" | "month";
}

// TODO: should come from shared directory
export interface Task {
	uid: string;
	name: string;
	completions: {
		uid: string;
		completedAt: Date;
		data: string;
	}[];
	createdAt: Date;
	type: TaskType;
	cadence: TaskCadence;
}

export default function TaskListContainer() {
	const { token } = React.useContext(AuthContext);
	const { apiClient } = React.useContext(ApiClientContext);

	const [selectedMonthYear, setSelectedMonthYear] = React.useState<Date>(
		new Date(),
	);

	const [tasks, setTasks] = React.useState<Task[]>([]);

	const reloadTasks = () => {
		if (!apiClient) return;

		apiClient("task")
			.then((res) => res.json())
			.then((data) => setTasks(data));
	};

	React.useEffect(() => {
		reloadTasks();
	}, [apiClient]);

	if (!token) {
		return <div>Not logged in</div>;
	}

	return (
		<>
			<MonthlyHeader
				selectedMonthYear={selectedMonthYear}
				setSelectedMonthYear={setSelectedMonthYear}
			/>
			<div className="h-12 w-full" /> {/* Spacer */}
			<MonthlyDaysTableHeader selectedMonthYear={selectedMonthYear} />
			<TaskList
				selectedMonthYear={selectedMonthYear}
				tasks={tasks}
				reloadTasks={reloadTasks}
			/>
			<div className="h-12 w-full" /> {/* Spacer */}
			<AddTaskButton reloadTasks={reloadTasks} />
		</>
	);
}
