import * as React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "styles/globals.css";
import ContentContainer from "./app/ContentContainer";
import TaskListContainer from "./app/taskList/TaskListContainer";
import { AuthProvider } from "./app/AuthContext";
import AuthenticationButton from "./app/AuthenticationButton";
import { ApiClientProvider } from "./app/ApiClientContext";

// TODO: REMOVE ME
const CLIENT_ID =
	"777318364719-noerhdapqqh6eq9lt6rr2n42uhg5ga18.apps.googleusercontent.com";

export default function App() {
	return (
		<div className="dark" style={{ height: "100vh" }}>
			<AuthProvider>
				<ApiClientProvider>
					<GoogleOAuthProvider clientId={CLIENT_ID}>
						<ContentContainer>
							<AuthenticationButton />

							<TaskListContainer />
						</ContentContainer>
					</GoogleOAuthProvider>
				</ApiClientProvider>
			</AuthProvider>
		</div>
	);
}
