import * as React from "react";

export default function MonthlyDaysTableHeader({
	selectedMonthYear,
}: {
	selectedMonthYear: Date;
}) {
	const currentDay = new Date().getDate();
	const currentMonth = new Date().getMonth();
	const currentYear = new Date().getFullYear();

	const daysInMonth = new Date(
		selectedMonthYear.getFullYear(),
		selectedMonthYear.getMonth() + 1,
		0,
	).getDate();

	const isAtCurrentMonthYear =
		selectedMonthYear.getMonth() === currentMonth &&
		selectedMonthYear.getFullYear() === currentYear;

	return (
		<div className="flex">
			<div>
				<div className="flex">
					{Array.from(Array(daysInMonth).keys()).map((day) => {
						const firstInitialOfTheDay = new Date(
							selectedMonthYear.getFullYear(),
							selectedMonthYear.getMonth(),
							day + 1,
						)
							.toLocaleString("default", {
								weekday: "long",
							})
							.charAt(0);

						return (
							<div
								key={day}
								className={`
					flex justify-center items-center w-10 h-10
					border-solid border-2 border-white-500 text-lg ${
						day !== daysInMonth - 1 ? "border-r-0" : ""
					}
					border-b-0
					${isAtCurrentMonthYear && day + 1 === currentDay ? "bg-sky-500 border-sky-500 border-r-2" : ""}
					${isAtCurrentMonthYear && day === currentDay ? "border-l-0" : ""}`}
							>
								{firstInitialOfTheDay}
							</div>
						);
					})}
				</div>
				<div className="flex">
					{Array.from(Array(daysInMonth).keys()).map((day) => (
						<div
							key={day}
							className={`
					flex justify-center items-center w-10 h-10
					border-solid border-2 border-white-500 text-lg ${day !== daysInMonth - 1 ? "border-r-0" : ""}
					${isAtCurrentMonthYear && day + 1 === currentDay ? "bg-sky-500 border-t-0 border-sky-500 border-r-2" : ""}
					${isAtCurrentMonthYear && day === currentDay ? "border-l-0" : ""}`}
						>
							{day + 1}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
