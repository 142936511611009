import React, { createContext, useState } from "react";

// Defining the shape of the context
interface AuthContextType {
	token: string | undefined;
	setToken: (token: string | undefined) => void;
	logout: () => void;
}

// Creating the context
export const AuthContext = createContext<AuthContextType>(
	{} as AuthContextType,
);

interface AuthProviderProps {
	children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [token, setToken] = useState<string | undefined>(
		localStorage.getItem("token") ?? undefined,
	);

	React.useEffect(() => {
		if (token) {
			localStorage.setItem("token", token);
		}
	}, [token]);

	const logout = () => {
		localStorage.removeItem("token");
		setToken(undefined);
	};

	return (
		<AuthContext.Provider value={{ token, setToken, logout }}>
			{children}
		</AuthContext.Provider>
	);
};
