import * as React from "react";
import { Task } from "./TaskListContainer";
import { hasCadenceCompleted } from "./TaskList";

function getStreakOfDaysInSet(date: Date, task: Task) {
	const selectedDate = new Date(date);

	let completed = hasCadenceCompleted(task, selectedDate);

	let streak = 0;

	while (completed) {
		streak++;

		// subtract the cadence of the task from the date
		if (task.cadence.period === "day") {
			selectedDate.setDate(selectedDate.getDate() - task.cadence.value);
		} else if (task.cadence.period === "week") {
			selectedDate.setDate(
				selectedDate.getDate() - 7 * task.cadence.value,
			);
		} else if (task.cadence.period === "month") {
			selectedDate.setMonth(selectedDate.getMonth() - task.cadence.value);
		}

		completed = hasCadenceCompleted(task, selectedDate);
	}

	return streak;
}

export default function TaskStreakSummary({ task }: { task: Task }) {
	const currentStreak = getStreakOfDaysInSet(new Date(), task);
	let bestStreak = 0;

	for (const { completedAt } of task.completions) {
		const streak = getStreakOfDaysInSet(new Date(completedAt), task);

		if (streak > bestStreak) {
			bestStreak = streak;
		}
	}

	return (
		<>
			<span className="text-lg">Current: {currentStreak}</span>
			<span className="text-lg ml-8">Best: {bestStreak}</span>
		</>
	);
}
