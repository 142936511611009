import { Button } from "components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Task } from "./TaskListContainer";
import * as React from "react";
import { ApiClientContext } from "../ApiClientContext";
import EmojiPicker, { Theme, Categories } from "emoji-picker-react";
import { AuthContext } from "../AuthContext";

export default function TaskCompleteDialog({
	task,
	reloadTasks,
	selectedDate,
	closeDialog,
}: {
	task?: Task;
	selectedDate?: Date;
	reloadTasks: () => void;
	closeDialog: () => void;
}) {
	const { apiClient } = React.useContext(ApiClientContext);
	const { token } = React.useContext(AuthContext);
	const [completedData, setCompletedData] = React.useState<string>("");
	const [completedDataError, setCompletedDataError] = React.useState<
		string | undefined
	>();
	const [photoUrl, setPhotoUrl] = React.useState<string | null>(null);
	const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

	const existingCompletedAt = task?.completions.find(
		(completion) =>
			new Date(completion.completedAt).getDate() ===
				selectedDate?.getDate() &&
			new Date(completion.completedAt).getMonth() ===
				selectedDate?.getMonth() &&
			new Date(completion.completedAt).getFullYear() ===
				selectedDate?.getFullYear(),
	);

	React.useEffect(() => {
		setCompletedData(existingCompletedAt?.data ?? "");
		setCompletedDataError(undefined);
		setPhotoUrl(null);

		if (existingCompletedAt && task?.type === "photo") {
			if (!apiClient) return;

			const fileName = existingCompletedAt?.data ?? "";

			apiClient(`file/${fileName}`, {
				method: "GET",
			}).then(async (res) => {
				const body = await res.json();

				if (res.ok) {
					setPhotoUrl(body.signedUrl);
				}
			});
		}
	}, [task, existingCompletedAt]);

	if (!task || !selectedDate) {
		return null;
	}

	const onDelete = async () => {
		if (!apiClient) return;

		if (!existingCompletedAt) return;

		const res = await apiClient(
			`task/${task.uid}/completed/${existingCompletedAt.uid}`,
			{
				method: "DELETE",
			},
		);

		if (res.ok) {
			reloadTasks();
		}

		closeDialog();
	};

	const onSubmit = async (data?: string) => {
		if (!apiClient) return;

		let res: Response;

		if (existingCompletedAt) {
			res = await apiClient(
				`task/${task.uid}/completed/${existingCompletedAt.uid}`,
				{
					method: "PUT",
					body: JSON.stringify({
						completedAt: selectedDate,
						data: data || completedData,
					}),
				},
			);
		} else {
			if (task.type === "photo") {
				if (selectedFile) {
					const formData = new FormData();
					formData.append("file", selectedFile);

					try {
						const results = await fetch(
							`${process.env.REACT_APP_API_URL}/file/upload`,
							{
								method: "POST",
								body: formData,
								headers: {
									Authorization: `Bearer ${token}`,
								},
							},
						).then((res) => res.json());

						data = results.fileName;
					} catch (error) {
						console.error("Error uploading file:", error);
					}
				}
			}

			res = await apiClient(`task/${task.uid}/completed`, {
				method: "POST",
				body: JSON.stringify({
					completedAt: selectedDate,
					data: data || completedData,
				}),
			});
		}

		if (res.ok) {
			reloadTasks();

			closeDialog();
		} else {
			// handle error
			const errors = await res.json();
			setCompletedDataError(errors.data);
		}
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			setSelectedFile(event.target.files[0]);
		}
	};

	return (
		<Dialog open={true} onOpenChange={closeDialog}>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>
						<>
							{task?.name} - {selectedDate.toDateString()}
						</>
					</DialogTitle>
				</DialogHeader>
				{task.type === "numerical" && (
					<div className="grid gap-4 py-4">
						<div className="grid grid-cols-4 items-center gap-4">
							<Label
								htmlFor="value"
								className={`text-right ${
									completedDataError ? "text-red-500" : ""
								}`}
							>
								Value{" "}
								{completedDataError
									? `(${completedDataError})`
									: ""}
							</Label>
							<Input
								id="value"
								placeholder=""
								className="col-span-3"
								value={completedData}
								onInput={(e) => {
									setCompletedData(e.currentTarget.value);
									setCompletedDataError(undefined);
								}}
							/>
						</div>
					</div>
				)}
				{task.type === "emoji" && (
					<div className="grid gap-4 py-4">
						<EmojiPicker
							onEmojiClick={async (e) => {
								await onSubmit(e.emoji);
							}}
							theme={Theme.DARK}
							reactionsDefaultOpen={true}
						/>
					</div>
				)}
				{existingCompletedAt && task.type === "photo" && photoUrl && (
					<img src={photoUrl} />
				)}
				{!existingCompletedAt && task.type === "photo" && (
					<div className="grid w-full max-w-sm text-left">
						<Input
							type="file"
							onChange={handleFileChange}
							className="file:text-white"
						/>
					</div>
				)}
				<DialogFooter>
					{existingCompletedAt && (
						<Button type="submit" onClick={onDelete}>
							Delete
						</Button>
					)}
					{task.type !== "emoji" &&
						(!existingCompletedAt || task.type === "numerical") && (
							<Button
								type="submit"
								onClick={async () => {
									await onSubmit();
								}}
							>
								{existingCompletedAt ? "Update" : "Complete"}
							</Button>
						)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
