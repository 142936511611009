import { Button } from "components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "components/ui/dialog";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Task } from "./TaskListContainer";
import * as React from "react";
import { useEffect } from "react";
import { DeleteTaskDialog } from "./DeleteTaskDialog";
import { ApiClientContext } from "../ApiClientContext";
import { TaskType } from "./TaskListContainer";

export default function AddEditTaskDialog({
	task,
	displayComponent,
	reloadTasks,
}: {
	task?: Task;
	displayComponent: React.ReactNode;
	reloadTasks: () => void;
}) {
	const { apiClient } = React.useContext(ApiClientContext);
	const FORM_DEFAULTS: Pick<Task, "name" | "type" | "cadence"> = {
		name: task?.name ?? "",
		type: task?.type ?? "boolean",
		cadence: task?.cadence ?? {
			value: 1,
			period: "day",
		},
	};

	const [formValues, setFormValues] =
		React.useState<Pick<Task, "name" | "type" | "cadence">>(FORM_DEFAULTS);
	const [formErrors, setFormErrors] = React.useState<{
		name?: string;
	}>({});

	const [open, setOpen] = React.useState(false);

	useEffect(() => {
		setFormValues(FORM_DEFAULTS);
		setFormErrors({});
	}, [open]);

	const onSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!apiClient) return;

		if (task) {
			// handle edit
			const res = await apiClient(`task/${task.uid}`, {
				method: "PUT",
				body: JSON.stringify(formValues),
			});

			if (res.ok) {
				await reloadTasks();

				setFormValues(FORM_DEFAULTS);
				setOpen(false);
			} else {
				// handle 400
				const errors = await res.json();
				setFormErrors(errors);
			}
		} else {
			// handle add
			const res = await apiClient(`task`, {
				method: "POST",
				body: JSON.stringify(formValues),
			});

			if (res.ok) {
				await reloadTasks();

				setFormValues(FORM_DEFAULTS);
				setOpen(false);
			} else {
				// handle 400
				const errors = await res.json();
				setFormErrors(errors);
			}
		}
	};

	const onDelete = async () => {
		if (!apiClient) return;

		const res = await apiClient(`task/${task?.uid}`, {
			method: "DELETE",
		});

		if (res.ok) {
			await reloadTasks();
		}

		setFormValues(FORM_DEFAULTS);
		setOpen(false);
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>{displayComponent}</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>{task ? "Edit" : "Add"} Task</DialogTitle>
				</DialogHeader>
				<div className="grid gap-4 py-4">
					<div className="grid grid-cols-4 items-center gap-4">
						<Label
							htmlFor="name"
							className={`text-right ${formErrors.name ? "text-red-500" : ""}`}
						>
							Name {formErrors.name && `(${formErrors.name})`}
						</Label>
						<Input
							id="name"
							placeholder="Example: Take Vitamins"
							className={`col-span-3 ${formErrors.name ? "border-red-500" : ""}`}
							value={formValues.name}
							onInput={(e) => {
								setFormValues({
									...formValues,
									name: e.currentTarget.value,
								});
								setFormErrors({});
							}}
						/>
					</div>
					<div className="grid grid-cols-4 items-center gap-4">
						<Label htmlFor="name" className="text-right">
							Type
						</Label>
						<Select
							onValueChange={(v) => {
								setFormValues({
									...formValues,
									type: v as TaskType,
								});
							}}
							defaultValue={formValues.type}
							disabled={!!task}
						>
							<SelectTrigger className="w-[240px]">
								<SelectValue placeholder="Task Type" />
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="boolean">Boolean</SelectItem>
								<SelectItem value="numerical">
									Numerical
								</SelectItem>
								<SelectItem value="emoji">Emoji</SelectItem>
								<SelectItem value="photo">Image</SelectItem>
							</SelectContent>
						</Select>
					</div>
					<div className="grid grid-cols-4 items-center gap-4">
						<Label htmlFor="name" className="text-right">
							Cadence
						</Label>
						<Select
							onValueChange={(v) => {
								setFormValues({
									...formValues,
									cadence: {
										...formValues.cadence,
										value: parseInt(v, 10),
									},
								});
							}}
							defaultValue={formValues.cadence.value.toString()}
							value={formValues.cadence.value.toString()}
						>
							<SelectTrigger className="w-[60px]">
								<SelectValue placeholder="Cadence Value" />
							</SelectTrigger>
							<SelectContent>
								<SelectItem value={"1"}>1</SelectItem>
								{["day", "week"].includes(
									formValues.cadence.period,
								) && <SelectItem value={"2"}>2</SelectItem>}
								{["day", "week"].includes(
									formValues.cadence.period,
								) && <SelectItem value={"3"}>3</SelectItem>}
								{["day", "week"].includes(
									formValues.cadence.period,
								) && <SelectItem value={"4"}>4</SelectItem>}
								{["day"].includes(
									formValues.cadence.period,
								) && <SelectItem value={"5"}>5</SelectItem>}
								{["day"].includes(
									formValues.cadence.period,
								) && <SelectItem value={"6"}>6</SelectItem>}
							</SelectContent>
						</Select>
						<Select
							onValueChange={(v) => {
								setFormValues({
									...formValues,
									cadence: {
										...formValues.cadence,
										period: v as "day" | "week" | "month",
										value: 1,
									},
								});
							}}
							defaultValue={formValues.cadence.period}
							value={formValues.cadence.period.toString()}
						>
							<SelectTrigger className="w-[180px]">
								<SelectValue placeholder="Cadence Period" />
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="day">Day(s)</SelectItem>
								<SelectItem value="week">Week(s)</SelectItem>
								<SelectItem value="month">Month(s)</SelectItem>
							</SelectContent>
						</Select>
					</div>
				</div>
				<DialogFooter>
					{task && (
						<DeleteTaskDialog
							task={task}
							onDeleteClick={onDelete}
						/>
					)}

					<Button type="submit" onClick={onSubmit}>
						{task ? "Save" : "Add"}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
