import * as React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { AuthContext } from "./AuthContext";
import { Button } from "components/ui/button";

export default function AuthenticationButton() {
	const { token, setToken, logout } = React.useContext(AuthContext);

	return token ? (
		<Button
			onClick={() => {
				logout();
			}}
		>
			Logout
		</Button>
	) : (
		<GoogleLogin
			onSuccess={async (credentialResponse) => {
				setToken(credentialResponse.credential);
			}}
			onError={() => {
				console.log("Login Failed");
			}}
		/>
	);
}
