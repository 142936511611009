import * as React from "react";

export default function ContentContainer({
	children,
}: {
	children?: React.ReactNode;
}) {
	return (
		<div className="flex flex-col items-center w-full h-full py-8">
			{children}
		</div>
	);
}
