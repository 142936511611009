import { Button } from "components/ui/button";
import AddEditTaskDialog from "./AddEditTaskDialog";
import * as React from "react";

export function AddTaskButton({ reloadTasks }: { reloadTasks: () => void }) {
	return (
		<AddEditTaskDialog
			displayComponent={<Button variant="outline">Add Task</Button>}
			reloadTasks={reloadTasks}
		/>
	);
}
