import * as React from "react";
import { Task } from "./TaskListContainer";
import AddEditTaskDialog from "./AddEditTaskDialog";
import TaskStreakSummary from "./TaskStreakSummary";
import TaskCompleteDialog from "./TaskCompleteDialog";

function convertDateIntoUsersTimezone(date: Date) {
	date = new Date(
		date.toLocaleString("en-US", { timeZone: "America/New_York" }),
	);

	const dateUtc = Date.UTC(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
	);

	return new Date(new Date(dateUtc + date.getTimezoneOffset() * 60 * 1000));
}

function isStartDayOfCadence(task: Task, date: Date) {
	if (task.cadence.period === "day") {
		// set to start of day
		const start = convertDateIntoUsersTimezone(task.createdAt);
		const end = convertDateIntoUsersTimezone(date);
		const daysSinceStart = Math.round(
			(end.getTime() - start.getTime()) / (1000 * 3600 * 24),
		);

		return daysSinceStart % task.cadence.value === 0;
	} else if (task.cadence.period === "week") {
		return date.getDay() === 1;
	} else if (task.cadence.period === "month") {
		return date.getDate() === 1;
	}
}

export function hasCadenceCompleted(task: Task, date: Date) {
	if (task.cadence.period === "day") {
		let i = 0;
		const currentDate = convertDateIntoUsersTimezone(date);
		const taskCreatedAt = convertDateIntoUsersTimezone(task.createdAt);

		do {
			const daysSinceStart = Math.round(
				(currentDate.getTime() - taskCreatedAt.getTime()) /
					(1000 * 3600 * 24),
			);

			if (daysSinceStart % task.cadence.value === 0) {
				break;
			}

			// go to previous day
			currentDate.setDate(currentDate.getDate() - 1);

			i++;
		} while (i < 7);

		// set to start of day
		const startOfCadence = new Date(currentDate);
		const endOfCadence = new Date(startOfCadence);
		// add cadence value to end of cadence
		endOfCadence.setDate(endOfCadence.getDate() + task.cadence.value);

		return task.completions.find((completion) => {
			const completionDate = convertDateIntoUsersTimezone(
				completion.completedAt,
			);
			return (
				completionDate >= startOfCadence &&
				completionDate < endOfCadence
			);
		});
	} else if (task.cadence.period === "week") {
		const isSunday = date.getDay() === 0;

		const startOfWeek = new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate() - (isSunday ? 6 : date.getDay() - 1),
		);

		const endOfWeek = new Date(
			startOfWeek.getFullYear(),
			startOfWeek.getMonth(),
			startOfWeek.getDate() + 7,
		);

		return task.completions.find((completion) => {
			const completionDate = new Date(completion.completedAt);
			return completionDate >= startOfWeek && completionDate < endOfWeek;
		});
	} else if (task.cadence.period === "month") {
		const beginningOfMonth = new Date(
			date.getFullYear(),
			date.getMonth(),
			1,
		);
		const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

		// find if there is a completion between the beginning and end of the month
		return task.completions.find((completion) => {
			const completionDate = new Date(completion.completedAt);
			return (
				completionDate >= beginningOfMonth &&
				completionDate <= endOfMonth
			);
		});
	}
}

export default function TaskList({
	tasks,
	selectedMonthYear,
	reloadTasks,
}: {
	tasks: Task[];
	selectedMonthYear: Date;
	reloadTasks: () => void;
}) {
	const [selectedTaskDate, setSelectedTaskDate] = React.useState<
		| {
				task: Task;
				date: Date;
		  }
		| undefined
	>(undefined);

	const daysInMonth = new Date(
		selectedMonthYear.getFullYear(),
		selectedMonthYear.getMonth() + 1,
		0,
	).getDate();

	const onDayClick = async (task: Task, day: number) => {
		setSelectedTaskDate({
			task,
			date: new Date(
				selectedMonthYear.getFullYear(),
				selectedMonthYear.getMonth(),
				day,
			),
		});
	};

	const daysTaskIsCompleted = (task: Task) => {
		const completedDays = new Map<number, string>();

		for (const { completedAt, data } of task.completions) {
			if (
				new Date(completedAt).getMonth() !==
					selectedMonthYear.getMonth() ||
				new Date(completedAt).getFullYear() !==
					selectedMonthYear.getFullYear()
			) {
				continue;
			}

			completedDays.set(new Date(completedAt).getDate(), data);
		}

		return completedDays;
	};

	const daysTaskCadenceIsCompleted = (task: Task) => {
		const completedDays = new Set<number>();

		for (let i = 1; i <= daysInMonth; i++) {
			const isCadenceCompleted = hasCadenceCompleted(
				task,
				new Date(
					selectedMonthYear.getFullYear(),
					selectedMonthYear.getMonth(),
					i,
				),
			);

			if (isCadenceCompleted) {
				completedDays.add(i);
			}
		}

		return completedDays;
	};

	const taskToDaysCompleted: Map<string, Map<number, string>> = new Map(); // task uid to day to completion data
	const taskToDayCadenceCompleted: Map<string, Set<number>> = new Map();
	const taskToStartDaysOfCadence: Map<string, Set<number>> = new Map();

	for (const task of tasks) {
		taskToDaysCompleted.set(task.uid, daysTaskIsCompleted(task));
		taskToDayCadenceCompleted.set(
			task.uid,
			daysTaskCadenceIsCompleted(task),
		);

		for (let i = 1; i <= daysInMonth; i++) {
			if (
				isStartDayOfCadence(
					task,
					new Date(
						selectedMonthYear.getFullYear(),
						selectedMonthYear.getMonth(),
						i,
					),
				)
			) {
				const startDays =
					taskToStartDaysOfCadence.get(task.uid) ?? new Set();
				startDays.add(i);

				taskToStartDaysOfCadence.set(task.uid, startDays);
			}
		}
	}

	const selectedMonthAtCurrentMonth =
		selectedMonthYear.getMonth() === new Date().getMonth() &&
		selectedMonthYear.getFullYear() === new Date().getFullYear();

	return (
		<>
			<TaskCompleteDialog
				task={selectedTaskDate?.task}
				selectedDate={selectedTaskDate?.date}
				reloadTasks={reloadTasks}
				closeDialog={() => {
					setSelectedTaskDate(undefined);
				}}
			/>

			{tasks.map((task) => (
				<div key={task.uid} className="flex justify-center">
					<div className="flex flex-row justify-end w-[300px]">
						<AddEditTaskDialog
							task={task}
							displayComponent={
								<p className="text-xl">{task.name}</p>
							}
							reloadTasks={reloadTasks}
						/>
					</div>
					<div className="flex w-1240px ml-8 mr-8">
						{Array.from(Array(daysInMonth).keys()).map((day) => (
							<div
								onClick={async () => {
									if (
										!selectedMonthAtCurrentMonth ||
										new Date().getDate() >= day + 1
									) {
										await onDayClick(task, day + 1);
									}
								}}
								key={day}
								style={{ lineHeight: "40px" }}
								className={`
					w-10 h-10 text-center
					border-solid border-2 border-white-500 text-lg ${
						day !== daysInMonth - 1 ? "border-r-0" : ""
					}
					border-t-0 
					${!selectedMonthAtCurrentMonth || new Date().getDate() >= day + 1 ? "hover:bg-green-400" : ""}
					${taskToDayCadenceCompleted.get(task.uid)?.has(day + 1) && "bg-green-300"}
					${taskToDaysCompleted.get(task.uid)?.has(day + 1) && "bg-green-700"}
					${day !== 0 && !taskToStartDaysOfCadence.get(task.uid)?.has(day + 1) ? "border-l-0" : ""}
					`}
							>
								{task.type === "emoji" &&
									taskToDaysCompleted
										.get(task.uid)
										?.has(day + 1) && (
										<>
											{taskToDaysCompleted
												?.get(task.uid)
												?.get(day + 1)}
										</>
									)}
							</div>
						))}
					</div>
					<div className="flex flex-row justify-start align-middle w-[300px]">
						<TaskStreakSummary task={task} />
					</div>
				</div>
			))}
		</>
	);
}
