import * as React from "react";
import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";

export default function MonthlyHeader({
	selectedMonthYear,
	setSelectedMonthYear,
}: {
	selectedMonthYear: Date;
	setSelectedMonthYear: (monthYear: Date) => void;
}) {
	const currentMonth = new Date().getMonth();
	const currentYear = new Date().getFullYear();

	const isAtCurrentMonthYear =
		selectedMonthYear.getMonth() === currentMonth &&
		selectedMonthYear.getFullYear() === currentYear;

	const onMonthClick = (delta: number) => {
		return () => {
			if (isAtCurrentMonthYear && delta > 0) return;

			selectedMonthYear.setMonth(selectedMonthYear.getMonth() + delta);

			setSelectedMonthYear(new Date(selectedMonthYear));
		};
	};

	return (
		<div className="flex justify-center">
			<span onClick={onMonthClick(-1)}>
				<ChevronLeftIcon className="h-8 w-8 text-blue-500 mr-8" />
			</span>
			<span className="text-xl">
				{selectedMonthYear.toLocaleString("default", {
					month: "long",
				})}
				{", "}
				{selectedMonthYear.getFullYear()}
			</span>
			<span>
				<ChevronRightIcon
					onClick={onMonthClick(1)}
					className={`h-8 w-8 ml-8 ${isAtCurrentMonthYear ? "disabled" : "text-blue-500"}`}
				/>
			</span>
		</div>
	);
}
