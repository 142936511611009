import React, { createContext } from "react";
import { AuthContext } from "./AuthContext";

// Defining the shape of the context
interface ApiClientContextType {
	apiClient?: typeof fetch;
}

// Creating the context
export const ApiClientContext = createContext<ApiClientContextType>(
	{} as ApiClientContextType,
);

interface ApiClientProviderProps {
	children: React.ReactNode;
}

export const ApiClientProvider: React.FC<ApiClientProviderProps> = ({
	children,
}) => {
	const { token, logout } = React.useContext(AuthContext);

	const apiClient = token
		? async (input: RequestInfo | URL, init?: RequestInit) => {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/${input}`,
					{
						...init,
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
							...init?.headers,
						},
					},
				);

				if (response.status === 403) {
					logout();
				}

				return response;
			}
		: undefined;
	return (
		<ApiClientContext.Provider value={{ apiClient }}>
			{children}
		</ApiClientContext.Provider>
	);
};
